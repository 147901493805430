.fields-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.fields-container-mobile {
  margin-top: 8px;
}

.text-error {
  margin-top: 8px;
  margin-bottom: 12px;
}

.aggreement {
  margin-top: 8px;
}

.aggreement > a {
  text-decoration: none;
  color: var(--primary_100);
}

.phone-field-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.textarea-wrapper {
  position: relative;
  display: flex;
  /**
     * reverse тут для того, чтобы при уменьшении ширины исчезали сначала элементы слева
     * Так же как это происходит с нативным селектом
     */
  flex-direction: row-reverse;
  max-width: 100%;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray20_100);
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  align-items: center;
  flex: 1 1 auto;
}

.textarea {
  padding-right: 8px;
  padding-left: 8px;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  resize: none;
  background: transparent;
  border: none;
  outline: none;
  order: 2;
}

.textarea::placeholder {
  color: var(--gray40_100);
}

.textarea-wrapper:hover {
  border-color: var(--black_100);
}

.textarea-wrapper:focus,
.focused {
  border-color: var(--primary_100);
  box-shadow: 0 0 0 1px var(--focused_15);
}

.textarea-wrapper:focus:hover,
.focused:hover {
  border-color: var(--primary_100);
}

.invalid {
  border-color: var(--error_100);
}

.invalid:hover {
  border-color: var(--error_100);
}
