.like-button {
  height: 28px;
  font-size: 14px;
  white-space: nowrap;
}

.callback-button {
  padding: 0;
  width: 28px;
  background-repeat: no-repeat;

  &.button-active {
    background-color: rgba(43, 135, 219, 0.15);
  }
}

.callback-button {
  background-image: url('./icons/callback.svg');
  background-position: 7px 7px;
}

.more-popup {
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.more-list {
  margin: 0;
  padding: 16px 24px;
  display: grid;
  list-style: none;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  color: #2b87db;
  place-items: center;

  &:hover {
    color: #256ba5;
  }
}

.more-item + .more-item {
  margin-top: 12px;
}

.more-link,
.more-item {
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.3s ease;

  &:hover {
    color: #e84260;
  }
}

.like-container {
  position: relative;
}

.delimiter {
  margin: 0;
  height: 1px;
  background-color: #e4e4e4;
  border: none;
}
