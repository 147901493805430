.container {
  right: 0;
  margin-top: 12px;
  padding: 25px 50px 28px 40px;
  width: 480px;

  &--submitted {
    width: 270px;
  }
}

.preloader {
  display: grid;
  place-items: center;
}

.form {
  position: relative;
}

.links {
  margin: 0;
  padding: 0;
  list-style: none;

  & > :last-child {
    margin-bottom: 0;
  }

  &-link {
    position: relative;
    font-size: 13px;
    font-weight: bold;

    &--active {
      margin-bottom: 11px;
    }
  }

  &-link-icon {
    position: absolute;
    top: 10px;
    left: -22px;
  }

  &-link-anchor {
    padding: 8px 0;
    display: block;
    line-height: 1.2;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }

    &--active {
      cursor: default;
      color: #0f0f0f;

      &:hover {
        color: #0f0f0f;
      }
    }
  }
}

.header {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
}

.textarea {
  margin-top: 2px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  min-height: 56px;
  font-size: 13px;
  resize: none;

  &--error {
    border-color: red;
  }
}

.button_back {
  position: absolute;
  top: -4px;
  left: -34px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #7a7a7a;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: #121212;
  }
}

.result {
  text-align: center;

  &-icon {
    margin: 0 auto;
    margin-bottom: 12px;
    display: block;
  }

  &-text {
    font-size: 16px;
    font-weight: bold;
  }
}

.report_container {
  padding: 16px 32px;
  display: grid;
  place-items: center;
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
