.realtor-contacts {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.realtor-contacts-header {
  width: 144px;
  align-self: flex-start;
  text-align: right;
  flex-shrink: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.info {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  row-gap: 16px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 50%);
  }
}

.socnetwork {
  margin-right: 12px;
  display: flex;
  width: 204px;
  word-break: break-word;
  align-items: center;
  gap: 12px;
}

.socnetwork-icon {
  width: 16px;
  height: 16px;
}

.phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.hidden-phone {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  cursor: pointer;
  gap: 12px;
}

.phone {
  white-space: nowrap;
  text-decoration: none;
}

.full-phone {
  display: flex;
  align-items: center;
  gap: 12px;
}

.calltracking-info {
  padding: 4px 8px;
  display: flex;
  background-color: #f3f6ff;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
