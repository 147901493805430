.vacancies {
  padding: 24px 24px 40px;
}

.vacancies-title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
}

.vacancy {
  margin: 24px 0;
  padding: 12px 13px 16px;
  display: block;
  text-decoration: none;
  color: #121212;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.vacancy:hover .vacancy-title {
  color: #e84260;
}

.vacancy-title {
  margin: 0 0 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}

.vacancy-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vacancy-body_block {
  padding-right: 20px;
  flex-grow: 1;
  flex-basis: 0;
}

.vacancy-body_title {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #7a7a7a;
}

.vacancy-body_value {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
}

.vacancies-all_link {
  font-size: 16px;
}
