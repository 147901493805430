.container {
  margin-bottom: 4px;
  display: flex;
  align-items: flex-start;
}

.icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background-image: url('./assets/arrowComplete.svg');
  background-repeat: no-repeat;
  background-position: center;
}
