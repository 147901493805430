.container {
  padding: 16px;
}

.header {
  margin-bottom: 24px;
}

.item {
  margin-bottom: 16px;
}
