.review_container {
  margin-top: 32px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 16px 15px;
  }
}

.review--refused {
  margin-top: 25px;
  margin-left: 34px;
  padding-left: 16px;
  border-left: 3px solid #f4f4f4;
}

.show-more-reviews-button-container {
  margin-top: 20px;
  padding-top: 16px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #e8e9ec;
}
.show-more-reviews-button-container > * {
  cursor: pointer;
}
