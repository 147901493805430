.container {
  position: absolute;
  inset: 0;
  user-select: none;
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;

  &-counter {
    position: absolute;
    left: 50%;
    bottom: 20px;
    visibility: visible;
    text-shadow: 0 0 5px black;
    color: white;
    transform: translateX(-50%);
  }

  &-full-height &-prev,
  &-full-height &-next {
    height: 100%;
  }

  &-prev,
  &-next {
    position: absolute;
    top: 50%;
    margin: 0;
    padding: 0;
    visibility: visible;
    width: 20%;
    height: 20%;
    cursor: pointer;
    color: white;
    background: none;
    border: none;
    transform: translateY(-50%);
    transform-origin: center;

    & > svg {
      overflow: visible;
      pointer-events: none;
      filter: drop-shadow(0 0 3px black);
    }

    &:focus {
      outline: 0;
    }
  }

  &-prev {
    left: 0;
  }

  &-next {
    right: 0;
  }
}
