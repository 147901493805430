.container {
  display: flex;
}

.avatar {
  margin-right: 16px;
  flex: 0 0 48px;
}

.content {
  flex: 1;
  width: calc(100% - 52px);
}

.title {
  display: flex;
  gap: 8px;
}

.info {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.date {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--dark_100);
}

.stars {
  margin: 0;
  padding: 0;
  display: flex;
}

.star {
  margin-left: 4px;
  display: block;
  width: 12px;
  height: 12px;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    display: block;
  }
}

.footer {
  margin-top: 8px;
  padding: 9px 0;
  display: flex;
}

.buttons {
  display: flex;

  & > :not(:first-child) {
    margin-left: 8px;
  }
}

.reply_button {
  padding: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: var(--primary_100);
  background: none;
  border: none;
  outline: none;
}

.edit_button {
  padding: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: var(--primary_100);
  background: none;
  border: none;
  outline: none;
}

.socnetwork {
  margin-top: 3px;
  display: inline-block;
}
