.container {
  padding: 6px 0;
  max-width: 290px;
  font-size: 14px;
  line-height: 20px;
}

.title {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.link {
  margin-bottom: 8px;
  display: block;
  text-decoration: none;
  color: var(--primary_100);
}

.title-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url('./assets/rgr_certificate_40.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.rgr-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
