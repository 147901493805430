.partner_badge {
  position: relative;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: #2e9e00;
  background-color: rgba(46, 158, 0, 0.1);
  border-radius: 2px;
}
