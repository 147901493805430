.container {
  overflow: hidden;
  max-height: 40px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.label {
  padding: 0 3px;
  display: inline-block;
  height: 18px;
  color: white;
  background-color: red;
  border-radius: 2px;
}
