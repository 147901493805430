.container {
  margin-bottom: 32px;
  display: flex;
}

.icon {
  flex: 0 0 40px;
  margin-right: 26px;
  height: 40px;
}

.text {
  flex-grow: 1;
}
