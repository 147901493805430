:root {
  --lineHeight: 21px;
}

.underground {
  margin-bottom: 6px;
  width: 100%;

  &-header {
    padding-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: black;

    &:hover {
      color: #e84260;
    }
  }

  &-distance {
    font-size: 12px;
    line-height: 16px;
    color: #7a7a7a;
  }

  &-ico {
    position: absolute;
    margin-top: 2px;
    margin-left: -21px;
    display: inline-block;
    line-height: var(--lineHeight);
  }
}
