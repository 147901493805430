.agent-section {
  margin-top: 8px;

  &:empty,
  &:first-child {
    margin: 0;
  }
}

.avatar {
  display: block;
  max-width: 100%;
  max-height: 60px;
}

.name {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.company-name {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.name + .company-name {
  margin-top: 0;
  color: #7a7a7a;
}

.text-badge {
  padding: 1px 6px 2px;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #585858;
  background-color: rgba(18, 18, 18, 0.1);
  border-radius: 2px;

  &.green {
    color: #2e9e00;
    background-color: rgba(46, 158, 0, 0.07);
  }
}

.badge {
  margin-right: 8px;
  display: inline-block;
}

.badge-container {
  display: flex;
  flex-flow: row wrap;
}

.other-offers {
  margin-top: 2px;
  display: block;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.3s ease;

  &:hover {
    color: #256ba5;
  }
}

.user-info {
  position: relative;
  z-index: 3;
  margin-right: -16px;
  padding: 6px 8px 4px;
  box-sizing: content-box;
  width: 144px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 1px;
  transition: background-color 0.3s ease;
  transform: translate(-8px, -4px);

  &:hover {
    z-index: 4;
    background-color: color(#2b87db alpha(0.1));
  }
}

.user-info-action {
  background-color: color(#2b87db alpha(0.1));
}
