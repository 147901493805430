.container {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 50vw;
  min-width: 262px;
}
.iframe-container {
  position: relative;
  height: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  min-height: -webkit-fill-available;
}

.iframe {
  display: block;
  width: 100vw;
  height: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  min-height: -webkit-fill-available;
  border: none;
}

.close {
  position: absolute;
  top: 14px;
  left: 14px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 740px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 741px) {
  .container {
    padding: 0 40px 24px;
    max-width: 582px;
  }
}
