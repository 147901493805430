.container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  min-width: 1000px;
  max-width: 1376px;
}

.content {
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  flex: 1 1 auto;
  flex-wrap: wrap;
  isolation: isolate;
}

.main {
  margin-right: 8px;
  width: calc(65% - 8px);

  & > section:last-child {
    margin-bottom: 0;
  }
}

.reviews {
  padding: 24px;
}

.aside {
  width: 35%;
}

.back_button {
  margin: 17px 0 9px 8px;
}

.back_button-link {
  font-size: 14px;
  text-decoration: none;
  color: #2b87db;
}

.back_button-link:hover {
  color: #256ba5;
}
