.standard {
  color: white;
  background-color: #ff5050;

  &:hover:not(:disabled) {
    background-color: #b23939;
  }
}

.light {
  color: #ff5050;
  background-color: rgba(255, 80, 80, 0.1);

  &:hover:not(:disabled) {
    color: #b23939;
    background-color: rgba(255, 80, 80, 0.15);
  }
}

.outline {
  color: #ff5050;
  border-color: #ff5050;

  &:hover:not(:disabled) {
    color: white;
    background-color: #ff5050;
  }
}
