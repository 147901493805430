.container {
  position: relative;
  height: 100%;
}

.promo-container {
  max-width: calc(100% - 30px);
}

.photos {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;
}
