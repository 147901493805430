.container {
  padding: 16px 20px;
  background-repeat: no-repeat;
  background-position: 65% -250px;
  background-size: 1024px;
}

.container_high_rate {
  background-image: url('./assets/bgHighRate.svg');
}

.container_low_rate {
  background-image: url('./assets/bgLowRate.svg');
}

.header_bar {
  margin-bottom: 18px;
}

.columns {
  margin-bottom: 32px;
  display: flex;
}

.left_column {
  flex-grow: 1;
  margin-right: 20px;
}

.right_column {
  flex: 0 0 70px;
  text-align: center;
}

.general_info {
  margin-bottom: 16px;
}

.rate {
  position: relative;
  left: -6px;
  display: inline-block;
}
