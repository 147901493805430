.container,
.link {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
}

.icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
