.header {
  margin-top: -2px;
  margin-left: -8px;
  padding: 2px 8px 4px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.term {
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 1.43;
  color: #7a7a7a;
}

.agent-bonus {
  margin: 5px 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  word-wrap: break-word;
  color: #555;
}

.agent-bonus > span {
  display: inline-block;
  white-space: nowrap;
}

.agent-bonus-info {
  position: relative;
  display: inline-block;
}

.agent-bonus-info svg {
  position: relative;
  top: 3px;
  margin-left: 5px;
  display: inline-block;
  width: 17px;
  height: 17px;
  fill: #7fbaf5;
}

.agent-bonus svg:hover + .agent-bonus-info-popup {
  visibility: visible;
  opacity: 1;
}

.agent-bonus-info-popup {
  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);
  left: calc(100% - 21px);
  padding: 12px 13px 15px 17px;
  visibility: hidden;
  box-sizing: border-box;
  width: 268px;
  font-size: 12px;
  font-weight: normal;
  color: #555;
  background-color: white;
  border: solid 1px #d9d9d9;
  border-radius: 3px;
  opacity: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
  transition:
    opacity 0.15s,
    visibility 0.15s;
}

.agent-bonus-info-popup::before {
  position: absolute;
  top: -11px;
  left: 6px;
  content: '';
  border: 6px solid transparent;
  border-bottom-color: white;
}

.agent-bonus-info-popup strong {
  display: block;
  color: #121212;
}

.mortgage-banner-wrapper {
  & > a {
    font-weight: normal;
    line-height: 1.33;
  }
}
