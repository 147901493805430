.poll {
  margin-left: auto;
  display: flex;
}

.vote_up,
.vote_down {
  flex: 0 0 auto;
  padding: 0;
  height: 20px;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  white-space: nowrap;
  cursor: pointer;
  color: #7a7a7a;
  background: none;
  border: none;
  outline: none;

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled),
  &.active {
    color: #121212;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }
}

.vote_down {
  svg {
    margin-bottom: -4px;
  }
}

.votes_count {
  margin-left: 4px;
}
