.link {
  font-size: 14px;
  line-height: 1.29;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.15s;
}

.link:hover {
  color: #1a62a6;
}

@media print {
  .link {
    color: #121212;
  }
}
