.container {
  margin-left: 15px;
  padding-right: 20px;

  & > * {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
}

.btn-map {
  position: relative;
  padding-left: 35px;
  font-size: 13px;
}

.like-container {
  position: relative;
  display: inline-block;
}

.icn-btn-map {
  position: absolute;
  top: 6px;
  left: 16px;
  margin-right: 5px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url('./icon_map.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.callback {
  position: relative;
  padding-left: 35px;
}

.callback::before {
  position: absolute;
  top: 6px;
  left: 16px;
  width: 14px;
  height: 14px;
  content: '';
  background-image: url('./assets/btn-callback.svg');
}

/**
* 1180 is custom width calculated by eyes
**/
@media (max-width: 1180px) {
  .callback {
    display: none;
  }
}
