.container {
  width: 140px;
  height: 28px;
}

.text {
  padding-top: 4px;
  padding-left: 16px;
  font-size: 16px;

  &.simplified-text {
    padding: 5px 0 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
    letter-spacing: normal;
    color: color(#121212 alpha(0.92));
  }
}

.work-time {
  font-size: 11px;
  font-weight: normal;
  line-height: 1.45;
  color: #7a7a7a;
  opacity: 0.92;
}
