.container {
  display: flex;
}
.rate {
  margin-right: 8px;
  padding: 0 8px;
  border-radius: 20px;
}

.rate_high {
  background-color: rgba(58, 197, 0, 0.1);
}

.rate_low {
  background: #fff5d4;
}
