.recidivist,
.excluded-from-action {
  margin-top: 12px;
  padding: 8px 12px 8px 32px;
  border-radius: 3px;
}

.recidivist {
  background-color: rgba(255, 80, 80, 0.1);

  > .svg-container {
    color: #ff5050;
  }
}

.excluded-from-action {
  background-color: rgba(122, 122, 122, 0.1);

  > .svg-container {
    color: #999;
  }
}

.svg-container {
  position: absolute;
  margin-top: 1px;
  margin-left: -19px;
  display: inline-block;
  width: 14px;
  height: 14px;
}

.recidivist-description {
  display: inline-block;
  overflow: hidden;
}

.link {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }

  &:visited {
    color: #909;
  }

  &:visited {
    color: #2b87db;
  }
}

.complaint {
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
}
