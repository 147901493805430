.standard {
  color: white;
  background-color: #2b87db;

  &:hover:not(:disabled) {
    background-color: #256ba5;
  }
}

.light {
  color: #2b87db;
  background-color: rgba(43, 135, 219, 0.1);

  &:hover:not(:disabled) {
    color: #256ba5;
    background-color: rgba(43, 135, 219, 0.15);
  }
}

.outline {
  color: #2b87db;
  border-color: rgba(43, 135, 219, 0.5);

  &:hover:not(:disabled) {
    color: white;
    background-color: #2b87db;
    border-color: #2b87db;
  }
}
