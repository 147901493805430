body {
  background-color: #f4f4f4;
}

/* stylelint-disable-next-line */
:global(#realtor-reviews-frontend) {
  margin-bottom: 48px;
  display: flex;
  color: #121212;
  background: var(--white_100);
  flex: 1 1 auto;

  @media (max-width: 1025px) {
    margin-bottom: 8px;
  }
}
