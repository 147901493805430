.agent_name {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
  opacity: 0.92;
}

.additional_phone {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
  opacity: 0.92;
}

.honest_work {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.phone {
  margin-top: 8px;
  display: block;
  font-weight: bold;
  line-height: 1.21;
  text-decoration: none;
  color: #182930;
}

.main_phones {
  margin-bottom: 10px;
}

.main_phone {
  font-size: 28px;
}

.main_phones_open {
  margin-top: 20px;
}

.rate_small {
  margin-left: 8px;
  font-size: 16px;
}

.reviews_info {
  margin-left: 5px;
  font-size: 16px;
  color: var(--gray60_100);
}

.stars {
  margin: 0;
  margin-left: 4px;
  padding: 0;
  display: flex;
}

.star {
  margin-left: 4px;
  display: block;
  width: 12px;
  height: 12px;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    display: block;
  }
}

.no_reviews {
  margin-bottom: 4px;
  font-size: 14px;
  color: #7a7a7a;
}

.rate_container {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.socnetworks {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.socnetwork {
  margin-left: 5px;
}

.socnetwork_name {
  margin-left: 5px;
}

.button_start_chat-container {
  margin-top: 16px;
}

.statistics {
  margin-top: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
}

.stats_param-value--green {
  color: #27a000;
}

.send-personal-lead-button {
  margin-top: 24px;
}

.circle {
  margin-left: var(--unit_1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #e6f0ff;
  border-radius: 50%;
}
