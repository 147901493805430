.popup {
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  transition:
    opacity 0.2s ease-out,
    visibility 0s 0.2s;
  transform: translateY(-100%);

  &.popup--visible {
    display: block;
    visibility: visible;
    opacity: 1;
    transition:
      opacity 0.2s ease-in,
      visibility 0s;
  }

  &::after {
    position: absolute;
    left: 40px;
    bottom: 0;
    display: block;
    width: 12px;
    height: 12px;
    content: '';
    background-color: white;
    box-shadow: inherit;
    transform: translateY(50%) rotate(315deg);
  }
}

.popup-container {
  position: relative;
  z-index: 1;
  min-width: 334px;
  color: #121212;
  background: white;
  border-radius: 2px;

  .content {
    padding: 0 50px 12px 16px;
  }

  .text {
    margin: 0;
    width: 245px;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    white-space: normal;
  }

  .title {
    margin-bottom: 8px;
    padding: 12px 16px;
    width: 100%;
    font-weight: bold;
    background-color: rgb(46, 158, 0, 0.1);

    svg {
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  .button-container {
    margin-top: 8px;
    display: flex;
    white-space: nowrap;
    flex-flow: row nowrap;
  }
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  transition: color 0.3s ease;

  &:hover {
    color: #121212;
  }
}

.button-create {
  margin-left: 5px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  cursor: pointer;
  color: #2b87db;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #256ba5;
  }
}
