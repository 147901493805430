.container {
  margin-bottom: 4px;
  display: block;
}

.title-link {
  text-decoration: none;
}

.title {
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px;
}

.honest-title-container {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.tag-pro {
  padding: 0 6px;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  white-space: nowrap;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  user-select: none;
  color: #585858;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 2px;
}

.title-property-container {
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title-icon {
  margin-right: 8px;
  display: inline-flex;
}

.title-property {
  font-weight: bold;
}
