.container {
  padding: 16px;
}

.header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-actions {
  margin-left: 12px;
  padding-top: 4px;
}

.rate_container {
  display: flex;
}

.rate {
  font-size: 70px;
  line-height: 84px;
}

.stars_container {
  margin-left: 24px;
  padding-top: 20px;
}

.stars {
  margin-bottom: 12px;
}

.reviews_container {
  margin-top: 34px;
}

.review {
  margin-bottom: 40px;
}

.review:last-of-type {
  margin-bottom: 15px;
}
