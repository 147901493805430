.container {
  margin-top: 4px;
}

.building-link {
  font-size: 14px;
  line-height: 1.43;
  text-decoration: none;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }

  &:visited {
    color: #909;
  }
}

.reliable-wrapper {
  margin: auto;
  cursor: pointer;

  &.external-reliable-icon {
    position: absolute;
    margin-left: -19px;
  }

  & .reliable-icon {
    width: 12px;
    height: 12px;
  }

  &.popup {
    left: -18px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: white;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
  }

  & .icon {
    margin: 0 0 5px;
    width: 48px;
    height: 48px;
  }

  & .title {
    margin: 0 0 4px;
    display: block;
    font-size: 16px;
  }

  & p {
    margin: 0 0 20px;
    font-size: 13px;
    line-height: 20px;
  }

  & .link {
    display: inline-block;
    width: 143px;
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    line-height: 25px;
    text-decoration: none;
    color: rgb(43, 135, 219);
    border: solid 1px rgba(43, 135, 219, 0.5);
    border-radius: 42px;
  }
}
