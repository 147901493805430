.container {
  position: relative;
  display: inline-block;
}

.button {
  font-size: 13px;
}

.icon {
  margin-left: 5px;
  width: 8px;
  height: 6px;
  transition: transform 0.15s;

  &--active {
    transform: rotate(180deg);
  }
}

.content {
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.popup {
  right: 0;
  margin-top: 12px;
  width: 220px;
}

.links {
  margin: 0;
  padding: 11px 10px 12px 20px;
  list-style: none;

  &-link {
    font-size: 13px;
    font-weight: bold;

    a,
    .print-button {
      padding: 8px 0;
      display: block;
      line-height: 1.2;
      text-decoration: none;
      cursor: pointer;
      color: #2b87db;

      &:hover {
        color: #e84260;
      }
    }
  }
}

.delimiter {
  margin: 0;
  height: 1px;
  background-color: #e4e4e4;
  border: none;
}

.report {
  padding: 16px 10px;
  display: grid;
  place-items: center;
}
