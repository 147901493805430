:root {
  --mediaWidth: 20%;
  --minContainerHeight: 246px;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #eef9ff;
    border-color: #deeaf1;
  }

  &--top3 {
    background-color: rgb(255, 244, 212);
    border-color: rgb(255, 176, 97);

    &:hover {
      background-color: rgb(255, 236, 189);
      border-color: rgb(255, 176, 97);
    }
  }

  &--colorized {
    background-color: rgb(255, 244, 212);

    &:hover {
      background-color: rgb(255, 236, 189);
    }
  }

  &.represent {
    background-color: rgba(46, 158, 0, 0.04);

    &:hover {
      background-color: rgba(46, 158, 0, 0.1);
    }
  }
}

.content {
  padding-top: 22px;
  padding-bottom: 21px;
  box-sizing: border-box;
  width: calc(100% - var(--mediaWidth));
  min-height: var(--minContainerHeight);
}

.media {
  position: relative;
  margin-right: 16px;
  width: var(--mediaWidth);
  min-width: var(--minContainerHeight);
}
