.advices {
  padding: 24px 24px 40px;
}

.advices-header {
  margin: 0 0 24px;
  display: flex;
  justify-content: space-between;
}

.advices-rating {
  padding-left: 22px;
  font-size: 16px;
  line-height: 22px;
  color: #7a7a7a;
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2210%22%3E%3Cpath%20fill%3D%22%237A7A7A%22%20fill-rule%3D%22evenodd%22%20d%3D%22M0%202h4v8H0V2zm5-2h4v10H5V0zm5%205h4v5h-4V5z%22%2F%3E%3C%2Fsvg%3E')
    no-repeat 0 7px;
}

.rating-link {
  text-decoration: none;
  color: inherit;
}

.rating-link:hover {
  color: #121212;
}

.advice {
  padding: 20px 0;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.advice > p {
  margin-left: 64px;
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.footer-text {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: var(--primary_100);
}

.advice-link {
  text-decoration: none;
  cursor: pointer;
  color: #121212;
}

.advice-link > span:first-child {
  margin-bottom: 16px;
}
.advice-link > *:nth-child(2) {
  margin-bottom: 28px;
}

.advice-answer {
  display: flex;
  gap: 16px;
}

.advice-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tabs {
  display: flex;
}

.tab {
  margin-right: 40px;
  padding-top: 8px;
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  color: var(--black_100);
  border-bottom: 2px solid transparent;
}

.tab-counter {
  margin-left: 8px;
  color: var(--gray60_100);
}

.tab--active {
  color: var(--primary_100);
  border-bottom: 2px solid var(--primary_100);
}

.question {
  margin-bottom: -1px;
  padding: 15px 0;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
