.container {
  display: flex;
}

.container_mobile {
  padding: 16px 0;

  .footer {
    display: block;
  }
  .footer .submit_button {
    margin-top: 20px;
    margin-left: 12px;
  }
}

.avatar {
  flex: 0 0 40px;
  margin-top: 10px;
  margin-right: 12px;
}

.content {
  flex: 1;
  padding: 10px 0 0;
  max-width: 100%;
  border-radius: 2px;

  @media (max-width: 1024px) {
    padding: 10px 0 0;
  }
}

.inactive_button {
  padding: 3px 8px 5px;
  display: block;
  width: 100%;
  height: 28px;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  cursor: text;
  color: #999;
  background-color: white;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;

  &:hover {
    background-color: white;
    border: solid 1px #7a7a7a;
    border-radius: 4px;
  }

  @media (max-width: 1024px) {
    padding: 7px 12px 11px;
    height: 40px;
    font-size: 16px;
    line-height: 1.38;
  }
}

.title {
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #121212;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.form {
  position: relative;
  z-index: 2;
  margin-top: 12px;
}

.stars {
  margin-top: -8px;

  &-tooltip {
    display: inline-block;
  }

  &-container {
    display: inline-block;
  }

  &-star {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1024px) {
      margin-right: 11px;
      width: 24px;
      height: 23px;
    }
  }

  &-description {
    margin: 0;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.33;
    color: #121212;

    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 1.29;
    }
  }

  @media (max-width: 1024px) {
    margin-top: -10px;
  }
}

.textarea {
  margin-top: 16px;
  display: block;
  width: 100%;
  resize: none;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }
}

.footer {
  z-index: 2;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  @media (max-width: 640px) {
    justify-content: flex-start;
  }
}

.checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;

  &-input {
    position: relative;
    appearance: none;
    width: 14px;
    height: 14px;
    cursor: inherit;
    background-color: white;
    border: 1px solid #c9c9c9;
    border-radius: 2px;
    outline: none;
    flex: 0 0 auto;

    &:hover:not(:disabled):not(:checked) {
      border-color: #7a7a7a;
    }

    &:focus:not(:disabled):not(:checked) {
      border-color: #121212;
    }

    &:disabled {
      opacity: 0.5;

      &:not(:checked) {
        background-color: #f4f4f4;
        border-color: #c9c9c9;
      }
    }

    &:checked {
      background-color: var(--primary_100);
      border-color: var(--primary_100);

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 75%;
        height: 75%;
        content: '';
        background-image: url('./assets/mark.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transform: translate(-50%, -50%);
      }
    }

    @media (max-width: 1024px) {
      width: 18px;
      height: 18px;
    }
  }

  &-label {
    margin-left: 8px;
    font-size: 14px;
    cursor: inherit;
    color: #121212;

    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 1.38;
    }
  }
}

.submit_button {
  display: inline-block;
}

.submit_button_wrapper + div {
  z-index: 2;
}

.description {
  margin-top: 5px;
  opacity: 0.5;

  mark {
    background-color: lightsalmon;
  }
}
