.button {
  position: relative;
  padding-left: 35px;
  font-size: 13px;
}

.icon {
  position: absolute;
  top: 6px;
  left: 16px;
  fill: none;

  &--active {
    fill: #e84260;
  }
}
