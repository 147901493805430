.review_form {
  margin-top: 32px;
}

.description {
  word-break: break-word;
}

.container {
  padding: 0 16px;
}
