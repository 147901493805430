.label {
  padding: 0 9px 0 10px;
  display: inline-block;
  font-size: 9px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  background-color: rgba(18, 18, 18, 0.7);
  border-radius: 3px;
}

.lock {
  margin-top: 3px;
  margin-right: 8px;
  float: left;
  width: 10px;
  height: 12px;
  background-image: url('./lock.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
