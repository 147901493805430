.underground-container {
  display: flex;
  flex-flow: row nowrap;
}

.icons {
  margin-right: 5px;
  box-sizing: border-box;
}

.underground-name {
  margin-right: 6px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  color: color(#121212 alpha(0.92));
  transition: color 0.3s ease;
}

.remoteness {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #7a7a7a;
}

.address-links {
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.underground-link {
  display: flex;
  text-decoration: none;
  flex-flow: row nowrap;

  &:hover .underground-name {
    color: #e84260;
  }
}

.address-link {
  font-size: 14px;
  line-height: 20px;
}

.link-container {
  display: inline-block;
}
