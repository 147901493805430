.container {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;

  &-container {
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    min-height: 114px;
  }
}

.media {
  margin-right: 27px;
}

.author-info {
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.phone {
  align-self: flex-end;
}

.chats-block {
  align-self: flex-end;
}

.chats-block .phone {
  margin-bottom: 10px;
}

.user-info {
  margin-right: -6px;
  margin-left: 0;
  padding-right: 0;
  width: 160px;
  align-self: flex-end;
}

.header {
  margin-bottom: 12px;
  &-title {
    margin: 0 0 4px;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
  }

  &-link {
    text-decoration: none;
    color: #2b87db;
  }

  &-class-type {
    margin-left: 6px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #7a7a7a;
  }

  &-sub-term {
    & div {
      display: inline-block;
    }

    &-list {
      margin: 0;
      padding: 0;
    }

    &-item {
      display: inline-block;
      &::after {
        margin: 0 8px;
        display: inline-block;
        content: '\2219';
      }
    }

    &-item:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.address {
  padding-left: 0;
}

.description {
  margin: 12px 0 20px;
  width: 625px;
  /* Значение взято из desctiption/index.css; line-height = 18px */
  height: calc(3 * 18px);
}

.offer-menu {
  margin-left: 0;
}

.footer {
  padding-right: 24px;
  display: flex;
  height: 28px;
  flex: 0 0 auto;
  align-items: center;
}

.footer-menu {
  margin-top: 0;
}

.recidivist {
  height: calc(3 * 18px + 13px);
}

.mortgage-banner {
  margin-left: 12px;
  display: inline-block;
}

@media (max-width: 1115px) {
  .author-info {
    display: none;
  }
}

@media (max-width: 1284px) {
  .hideable {
    display: none;
  }
}

.vas-list {
  margin-left: 18px;
  white-space: normal;
}
