.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name {
  margin-top: 30px;
  margin-bottom: 14px;
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
  color: #121212;
}

.offer {
  margin-bottom: 10px;
}

.link {
  margin-left: 20px;
  display: inline-block;
}
