.container {
  display: flex;
  align-items: center;
  gap: 8px;

  &.with-tooltip {
    cursor: pointer;
  }

  /* У тултипа из ui-kit есть баг, когда обработчик onOpen срабатывает повторно при наведении на дочерние элементы */
  & > * {
    pointer-events: none;
  }
}

.icon {
  position: relative;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;

  &.has-verified-documents {
    background-image: url('./assets/arrowCompleteNew.svg');
  }

  &.can-do-online-deal {
    background-image: url('./assets/document.svg');
  }
}

.tooltip-content {
  max-width: 300px;

  & > a {
    text-decoration: none;
    color: var(--primary_100);
  }
}
