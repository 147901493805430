.container {
  position: sticky;
  top: 8px;
  transition: top 0.25s;
}

.section {
  padding: 24px;
  box-sizing: border-box;
  background-color: var(--white_100);
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}
