.container {
  padding: 24px 16px;
}

.header {
  margin-bottom: 16px;
}

.expand {
  margin-top: 20px;
}

.expand_icon svg {
  vertical-align: middle;
}
