.button {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  display: flex;
  width: 42px;
  height: 42px;
  cursor: pointer;
  color: #7a7a7a;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.button:hover {
  color: #121212;
}

.button--white {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  display: flex;
  width: 42px;
  height: 42px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: white;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
