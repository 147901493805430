.container_inner {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container-mobile_inner {
  padding: 0 16px 16px;
}

.text {
  margin: 0;
  margin-top: 18px;
  max-width: 412px;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #121212;
}

.container_inner > button,
.container-mobile_inner > button {
  margin-top: 24px;
}

.container_inner > p,
.container-mobile_inner > p {
  margin-top: 8px;
  word-break: break-word;
}
