.suggest {
  position: absolute;
  z-index: 1;
  margin-top: 4px;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 188px;
  background: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.suggest-item {
  padding: 8px 12px;
  cursor: pointer;
}

.suggest-error {
  padding: 8px 12px;
}

.suggest-item:hover {
  background-color: #e9f3fb;
}
