.review {
  margin: 16px 0 0;
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-line;
  word-wrap: break-word;
  color: var(--dark_100);
  background-color: var(--gray6_100);
  border-radius: 0 12px 12px;
}

.review--refused {
  color: var(--gray60_100);
}

.review_view_all {
  margin: 4px 0 12px;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: var(--primary_100);
  background: none;
  border: none;
  outline: none;
}
