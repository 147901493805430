.container {
  display: flex;
  overflow: hidden;
  width: 48px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
}
