.container {
  position: relative;
}

.input-container {
  position: relative;
  z-index: 2;
}

/* stylelint-disable-line */ /* TODO перепроверить и убрать сложный селектор по возможности */
.input-container > div {
  background-color: transparent;
}
