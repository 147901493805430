.offer-container {
  box-sizing: border-box;
  max-height: 1000vh;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 1px;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    background-color: #eef9ff;
  }

  & + & {
    border-top: none;
  }

  &.offer-hidden {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition:
      opacity 0.3s ease,
      max-height 0s 0.3s;
  }
}

.offer-container.top3 {
  background-color: #fff5d4;
  border-color: #ffc55d;

  &:hover {
    background-color: #ffecbd;
  }
}

.offer-container.top3 {
  background-color: white;
  border-color: white;

  &:hover {
    background-color: white;
  }
}

.offer-container.colorized {
  background-color: #fff5d4;

  &:hover {
    background-color: #ffecbd;
  }
}

.container {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  color: #121212;
  flex: 0 0 auto;

  &.offer-unclickable {
    cursor: default;
  }

  &.offer-similar {
    padding-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover .dynamic-button,
  &.touch-device .dynamic-button {
    opacity: 1;
  }

  .header {
    text-decoration: none;
    color: #121212;
    transition: color 0.3s ease;
  }

  &:not(.offer-unclickable):hover .header,
  .header:hover {
    color: #e84260;
  }
}

.main-container {
  display: flex;
}

.main {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.media {
  margin-right: 16px;
  min-width: 262px;
  height: 226px;
  flex-basis: 262px;
}

.panels-container {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.left-panel {
  display: flex;
  flex-flow: row nowrap;

  .button {
    margin-top: auto;
    height: 28px;
  }

  .button:not(:first-child) {
    margin-left: 8px;
  }

  .dynamic-button {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.right-panel {
  display: flex;
  flex-flow: row nowrap;

  .label,
  .time {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-block;
  }

  .time {
    position: relative;
    top: 2px;
    cursor: default;
  }

  .time:not(:only-child) {
    margin-right: 8px;
  }

  .label {
    margin-right: 4px;
  }

  .label:last-child {
    margin-right: 0;
  }
}

.agent {
  margin-left: 24px;
  width: 144px;
}

.info-container {
  display: flex;
  flex-flow: row nowrap;
}

.info {
  display: flex;
  width: 80%;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  .info-section + .info-section {
    margin-top: 12px;
  }
}

.sub-block {
  margin-top: 16px;
}

.main-info {
  padding-top: 4px;
  display: flex;

  & > div:first-child {
    margin-right: 16px;
    min-width: 282px;
  }
}

.similar {
  padding-left: 278px;
  display: flex;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 45px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-top: 1px solid #e4e4e4;
  transition: color 0.3s ease;

  &:hover {
    color: #256ba5;
  }
}

.map-container {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

.map {
  height: 248px;
  transition: transform 0.3s ease;
  transform: translateY(-100%);
}

.map-active {
  height: 248px;

  .map {
    transform: translateY(0);
  }
}

.base-label {
  margin-top: 4px;
}

.vas-list {
  margin-left: 10px;
  padding-top: 7px;
}
