.container {
  width: 100%;
}

.block {
  margin-bottom: 16px;
  background-color: white;
  border-radius: 8px;
}

.block:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.block:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.block.actions {
  margin-bottom: 16px;
  background-color: transparent;
  border-radius: 0;
}
