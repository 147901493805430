.image_wrp {
  position: relative;
}

.image_wrp img {
  object-position: top;
}

.shield {
  position: absolute;
  top: calc(14.6% - 8px);
  right: calc(14.6% - 8px);
  width: 16px;
  height: 16px;
}
