.success-block {
  display: flex;
}

.success-block::before {
  margin-right: 20px;
  font-size: 24px;
  content: '📭';
}

.mobile-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.text-wrapper {
  margin-top: 4px;
  margin-bottom: auto;
}

.mobile-content::before {
  margin-top: 28vh;
  margin-bottom: 21px;
  width: 68px;
  height: 68px;
  content: '';
  background-image: url('./assets/check.svg');
}
