.container {
  margin-top: 20px;
  margin-left: 34px;
  padding-left: 20px;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
}

.answer_container {
  margin-top: 24px;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
}

.answers_view_all {
  margin-top: 12px;
  margin-left: 52px;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  cursor: pointer;
  color: #2b87db;
  background: none;
  border: none;
  outline: 0;

  svg {
    position: relative;
    top: -1px;
    margin-left: 4px;
    width: 10px;
    height: 6px;
    transition: transform 0.3s;
  }

  &:hover {
    color: #256ba5;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
}

.answers_view_all--active {
  svg {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}

.moderation_container {
  margin-top: 25px;
}
