.container {
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;

  @media (max-width: 1024px) {
    padding-bottom: 20px;
    min-height: 72px;
  }
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.action-list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.action-list > li:not(:first-child) {
  margin-left: 16px;
}

.rating-counters {
  display: flex;
  align-items: center;
}
.rating-summary {
  margin-top: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rate-description {
  font-size: 16px;
  line-height: 1.25;
  white-space: nowrap;
  color: #121212;
}

.rate-aside-info {
  margin-left: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.rate-value {
  margin-top: 4px;
}

.rate-value > :not(:first-child) {
  margin-left: 4px;
}

.invisible {
  display: none;
}

@media (max-width: 1024px) {
  .title-wrapper {
    position: relative;
  }

  .rate-list {
    margin-top: 13px;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
  }
}
