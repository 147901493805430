.container {
  position: sticky;
  z-index: 5;
  bottom: 20px;
  margin: 20px auto;
  padding: 0 16px;
  display: flex;
  width: 100%;
}

.item {
  margin-right: 8px;
  flex-basis: 50%;
}

.item-has-one {
  flex-basis: 100%;
}

.item:last-of-type {
  margin-right: 0;
}
