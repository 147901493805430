.tooltip_content {
  position: absolute;

  &--left {
    left: -11px;
    transform: translateX(-100%);
  }

  &--right {
    right: -11px;
    transform: translateX(100%);
  }

  &--top {
    top: -11px;
    right: 50%;
    transform: translate(50%, -100%);
  }

  &--bottom {
    right: 50%;
    bottom: -11px;
    transform: translate(50%, 100%);
  }
}

.tooltip_wrapper {
  position: relative;
  padding: 7px 12px 9px;
  width: 194px;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  background-color: white;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.tooltip_text {
  font-size: 12px;
  color: #121212;
}

.entering {
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.enter {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    visibility 0s,
    z-index 0s;
}

.leave {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0s 0.3s,
    z-index 0s 0.3s;
}
