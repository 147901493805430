:root {
  --bg-padding: 10px;
  --avatar-padding: 5px;
}

.container {
  position: relative;
  text-overflow: ellipsis;
}

.user-info {
  padding: 3px var(--bg-padding) 8px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  -webkit-user-drag: none;

  &--active,
  &:hover {
    background-color: rgba(18, 18, 18, 0.03);
  }
}

.popup {
  z-index: 6;
  right: 0;
  margin-top: 12px;
  width: 240px;
}

.links {
  margin: 0;
  padding: 11px 10px 12px 20px;
  list-style: none;

  &-link {
    font-size: 13px;
    font-weight: bold;

    svg {
      margin-right: 7px;
      vertical-align: top;
    }

    a {
      padding: 8px 0;
      display: block;
      line-height: 1.2;
      text-decoration: none;
      color: #2b87db;

      &:hover {
        color: #e84260;
      }
    }
  }
}

.delimiter {
  margin: 0;
  height: 1px;
  background-color: #e4e4e4;
  border: none;
}

.all-offers {
  padding: 7px 0 10px;
  text-align: center;

  a {
    margin: 0 10px;
    padding: 7px 0 8px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }
  }
}

.container-reverse {
  .all-offers {
    padding: 20px;
    line-height: 16px;
    text-align: left;
  }

  .offer-link,
  .link-item {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }

  .offer-link + .offer-link,
  .links-link + .links-link {
    margin-top: 16px;
  }

  .links {
    padding: 20px;
  }

  .links-link svg {
    margin-right: 8px;
  }
}

.popup_content {
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
