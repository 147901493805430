:root {
  --lineHeight: 18px;
}

.description {
  position: relative;
  margin: 22px 40px 14px 20px;
  overflow: hidden;
  height: calc(2 * var(--lineHeight));
  font-size: 13px;
  line-height: var(--lineHeight);

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px;
    height: var(--lineHeight);
    content: '';
  }
}

.highlighted-word {
  padding: 0 3px;
  display: inline-block;
  height: 18px;
  color: white;
  background-color: red;
  border-radius: 2px;
}

@media print {
  .description {
    height: auto;
  }

  .description::after {
    display: none;
  }
}

.recidivist,
.excluded-from-action {
  margin-right: 8px;
  padding: 5px 8px 8px 31px;
  height: inherit;
  border-radius: 3px;
}

@media (max-width: 1231px) {
  .recidivist,
  .excluded-from-action {
    margin-right: 36px;
  }
}

.recidivist {
  background-color: rgba(255, 80, 80, 0.1);

  > .svg-container {
    color: #ff5050;
  }
}

.excluded-from-action {
  background-color: rgba(122, 122, 122, 0.1);

  > .svg-container {
    color: #999;
  }
}

.recidivist-description {
  display: inline-block;
  overflow: hidden;
}

.svg-container {
  position: absolute;
  margin-top: 2px;
  margin-left: -19px;
  display: inline-block;
  width: 14px;
  height: 14px;
}

.link {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }

  &:visited {
    color: #909;
  }

  &:visited {
    color: #2b87db;
  }
}

.complaint {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
  color: #2b87db;
}
