.container {
  position: relative;
  padding: 24px;
  padding-bottom: 40px;
  min-height: 220px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.row:not(:first-child) {
  margin-top: 12px;
}
.row > :not(:first-child) {
  margin-left: 32px;
}

.name-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.title {
  display: flex;
  gap: 0;
  flex-basis: 80%;
  flex-wrap: wrap;
}

.title > :not(:first-child) {
  margin-top: 12px;
}

.agent-name {
  flex-basis: 100%;
}

.name {
  margin-right: 8px;
}

.badges {
  position: relative;
  top: 2px;
  display: inline-flex;
  gap: 4px;
}

.honest_work {
  margin-right: 10px;
  margin-bottom: 5px;
}

.avatar-container {
  padding: 12px;
}

.avatar {
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  font: 0/0 A;
  text-align: center;
  background-color: white;
  border: 1px solid rgba(170, 170, 170, 0.14);
  border-radius: 0;
}

.avatar_roundness {
  border-radius: 50%;
}

.avatar_image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-size: cover;
}

.no_photo {
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  font-size: 0;
  line-height: 0;
  text-align: center;
  border: 1px solid rgba(170, 170, 170, 0.14);
}

.rating {
  align-self: flex-start;
  flex-shrink: 0;
  max-width: 144px;
}

/* Размер одной звезды */
.rate-value > li {
  width: 24px;
  height: 24px;
}
.rate-value > :not(:first-child) {
  margin-left: 6px;
}

.rating-desctiption {
  text-align: center;
}

.counters {
  padding: 8px 16px;
  display: flex;
  background-color: var(--gray6_100);
  border-radius: 4px;
  flex-grow: 1;
}

.counters > * {
  margin-right: 20px;
}

@media screen and (min-width: 1024px) {
  .counters > * {
    margin-right: 28px;
  }
}

@media screen and (min-width: 1200px) {
  .counters > * {
    margin-right: 48px;
  }
}

.counters-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description-container {
  margin-top: 36px;
}

.description-container > .row {
  margin-top: 24px;
}

.about-title {
  width: 144px;
  align-self: flex-start;
  text-align: right;
  flex-shrink: 0;
}

.about-text {
  display: inline;
  word-break: break-word;
}

.maximize-button {
  cursor: pointer;
}

.column {
  margin-top: var(--unit_0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge {
  margin-top: var(--unit_2);
}
